<template>
  <div>
        <b-button
            id="buttonCommentsId"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-dark"
            class="btn-icon rounded-circle"
            v-b-tooltip.hover.on.v-dark
            title="Comentarios"
            @click="getComments()"
        >
            <feather-icon icon="MessageSquareIcon" />
        </b-button>
        
        <b-modal
            ref="modal-comments"
            :id="`modal-${question ? question.id : 'y'}`"
            centered
            size="lg"
            :title="question ? question.title : titleModal"
            modal-class="modal-primary"
            no-close-on-backdrop
            no-close-on-esc
        >

            <div class="contentModal mt-1">{{ descriptionModal }}</div>            
            
           <b-form-textarea
                id="textarea"            
                placeholder="Ingrese texto..."
                v-model="commentModal"
                class="my-2"
                rows="5"
            >                
            </b-form-textarea>

            <template #modal-footer>

                <b-button
                    variant="primary"
                    class="float-right"
                    @click="updateComments()"
                >
                    Comentar
                </b-button>

                <b-button variant="outline-danger" @click="cancelar()">
                    Cancelar
                </b-button>
            </template>
        </b-modal>
        
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
export default {
    props: {
        comment: String,
        question: Object,        
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            titleModal: "",
			descriptionModal: "Comentario:",	
            commentModal: "",
        }
    },
     watch: {
        comment() {
            this.commentModal = this.comment;
        }
    },
    methods: {
        getComments(){                  
            this.$refs['modal-comments'].show();
        },
        updateComments(){
            let data = {
                comment : this.commentModal,
                i: this.question.id,
            }
            this.$emit('update-comment', data); 
            this.$refs['modal-comments'].hide();
        },
        cancelar(){
            this.commentModal = this.comment;
            this.$refs['modal-comments'].hide();
        }
    }
}
</script>

<style scoped>

    
</style>